import { withPrefix } from "gatsby";
import React from "react";
import ProductsList from "../../productsList/ProductsList";
import Spin from "../../spin/Spin";
import './style.scss'

const End = ({
  heading,
  description,
  loading,
  products,
  productButton,
  productAction,
}) => {

  return (
    <section className="quizEnd"
      style={{
        backgroundColor: '#F8F8F8',
        backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundBlendMode: 'multiply'
      }}
    >
      <div className="page">
        <div className="container">
          <div className="quizEnd__wrapper">
            {heading ? (
              <h2 className="quizEnd__wrapper__heading heading--dash heading--center heading--bigNormal">
                {heading}
              </h2>
            ) : ''}
            {description ? (
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            ) : ''}
          </div>
          <div className="quizEnd__products">
            {loading && products ?
              (
                <Spin />
              )
              : (
                <>
                  <ProductsList
                    action={productAction}
                    productBoxButton={productButton}
                    products={products}
                    columns={4}
                    grid={false}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default End;
