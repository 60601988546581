import React, { useState } from "react";
import Button from "../../button/Button";
import './style.scss'

const QuestionSingle = ({
  index,
  currentStepQuestion,
  setCurrentStepQuestion,
  allQuestions,
  question,
  answers,
  setAnswer,
  answer,
  button,
  lastStepAction,
  extraInfo,
  setExtraInfo,
}) => {

  const [currentAnswer, setCurrentAnswer] = useState('a');
  const handleNextStep = () => {
    setExtraInfo('');

    setAnswer([
      ...answer,
      currentAnswer
    ])

    if (allQuestions === index) {
      lastStepAction()
    }
    else {
      setCurrentStepQuestion(currentStepQuestion + 1);
    }

  }

  return (
    <div className={`QuestionSingle ${currentStepQuestion === index ? 'QuestionSingle--active' : ''}`}>
      <div className="QuestionSingle__wrapper">
        <p className="QuestionSingle__step">
          {currentStepQuestion}/{allQuestions}
        </p>
        <h2 className="QuestionSingle__heading heading--normal">
          {question}
        </h2>
        <div className="QuestionSingle__answers">
          {answers.map((answersItem, index) => (
            <>
              <div className="form__radio" key={`${question}_answer_${index}`}>
                <label htmlFor={`${question}__${answersItem.typeAnswer}`}>
                  <input type="radio"
                    checked={currentAnswer === answersItem.typeAnswer ? 'checked' : ''}
                    name={question}
                    value={answersItem.typeAnswer}
                    id={`${question}__${answersItem.typeAnswer}`}
                    onChange={(e) => {
                      setCurrentAnswer(e.target.value);
                      if (answersItem.extraInfo) {
                        setExtraInfo(answersItem.extraInfo);
                      }
                      else {
                        setExtraInfo('');
                      }
                    }}
                  />
                  <span className="form__radio__label">
                    {answersItem.answer}
                  </span>
                </label>
              </div>

            </>
          ))}
        </div>
        <div className="QuestionSingle__button">
          <Button url="#" title={button} action={(e) => { handleNextStep(e) }} />
        </div>
      </div>
    </div>
  );
};

export default QuestionSingle;
