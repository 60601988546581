import { withPrefix } from "gatsby";
import React, { useState } from "react";
import QuestionSingle from "./QuestionSingle";
import './style.scss'

const Question = ({
  questions,
  nextStepButton,
  lastStepButton,
  setAnswer,
  answer,
  lastStepAction
}) => {

  const questionItemCount = questions.length;
  const [currentStepQuestion, setCurrentStepQuestion] = useState(1);
  const [extraInfo, setExtraInfo] = useState('');

  return (
    <section className="quizQuestion"
      style={{
        backgroundColor: '#F8F8F8',
        backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundBlendMode: 'multiply'
      }}
    >
      <div className="container">
        <div className="quizQuestion__wrapper">
          {questions.map((questionItem, index) => (
            <QuestionSingle
              lastStepAction={lastStepAction}
              key={`questionItem_${index}`}
              index={index + 1}
              currentStepQuestion={currentStepQuestion}
              setCurrentStepQuestion={setCurrentStepQuestion}
              allQuestions={questionItemCount}
              question={questionItem.question}
              extraInfo={questionItem.extraInfo}
              setExtraInfo={setExtraInfo}
              answers={questionItem.answers}
              setAnswer={setAnswer}
              answer={answer}
              button={questionItemCount === (index + 1) ? lastStepButton : nextStepButton}
            />
          ))}
          {extraInfo ? (
            <div class="quizQuestion__extraInfo">
              <h3 class="heading--small"><span dangerouslySetInnerHTML={{ __html: extraInfo }} /></h3>
            </div>
          ) : ''}
        </div>
      </div>
    </section>
  );
};

export default Question;
