import React from "react";
import Button from "../../button/Button";
import Img from "gatsby-image"
import './style.scss'

const Start = ({
  startButtonText,
  startDescriptionAfterButton,
  startDescriptionBeforeButton,
  startHeading,
  setStep,
  startBackgroundImage
}) => {

  return (
    <section className="quizStart">
      <div className="quizStart__background">
        <Img fluid={startBackgroundImage.imageFile.childImageSharp.fluid} />
      </div>
      <div className="quizStart__content">
        <div className="container container--small">
          <h1 className="heading--xl">
            {startHeading}
          </h1>
          {startDescriptionBeforeButton ? (
            <div className="quizStart__content__beforeButton content">
              <div dangerouslySetInnerHTML={{ __html: startDescriptionBeforeButton }} />
            </div>
          ) : ''}
          <div className="quizStart__content__button">
            <Button url="#" title={startButtonText} action={() => { setStep(2) }} />
          </div>
          {startDescriptionAfterButton ? (
            <div className="quizStart__content__afterButton content">
              <div dangerouslySetInnerHTML={{ __html: startDescriptionAfterButton }} />
            </div>
          ) : ''}
        </div>
      </div>
    </section>
  );
};

export default Start;
